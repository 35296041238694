(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play/assets/javascripts/skeleton/route-play-external-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play/assets/javascripts/skeleton/route-play-external-fallback.js');
"use strict";

const {
  CenterToScreen
} = svs.components.tipsen.centerToScreen;
const {
  SkeletonLine
} = svs.components.lbUi;
const {
  ReactPaper
} = svs.ui;
const RoutePlayExternalFallback = () => React.createElement(CenterToScreen, null, React.createElement("div", {
  className: "external-systems-skeleton"
}, React.createElement(ReactPaper, {
  branding: "sport",
  className: "skeleton-prompt"
}, React.createElement(SkeletonLine, {
  className: "skeleton-external-title-md"
}), React.createElement(SkeletonLine, {
  className: "skeleton-external-title-sm"
}), React.createElement(SkeletonLine, {
  className: "skeleton-external-title-lg"
})), React.createElement(ReactPaper, {
  branding: "sport",
  className: "skeleton-article"
}, React.createElement(SkeletonLine, {
  className: "skeleton-external-title-lg"
}), React.createElement(SkeletonLine, {
  className: "skeleton-external-paragraph"
}), React.createElement(SkeletonLine, {
  className: "skeleton-external-paragraph"
}), React.createElement(SkeletonLine, {
  className: "skeleton-external-paragraph"
}), React.createElement(SkeletonLine, {
  className: "skeleton-external-paragraph"
}), React.createElement(SkeletonLine, {
  className: "skeleton-external-paragraph"
}))));
setGlobal('svs.components.tipsen.routePlay.RoutePlayExternalFallback', RoutePlayExternalFallback);

 })(window);