(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play/assets/javascripts/skeleton/route-play-pix-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play/assets/javascripts/skeleton/route-play-pix-fallback.js');
"use strict";

const {
  SkeletonLine
} = svs.components.lbUi;
const RoutePlayPixFallback = () => React.createElement("div", {
  className: "coupon-pix-row-skeleton"
}, React.createElement(SkeletonLine, {
  className: "coupon-pix-team-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-pix-team-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-pix-team-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-pix-team-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-pix-team-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-pix-team-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-pix-team-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-pix-team-skeleton"
}));
setGlobal('svs.components.tipsen.routePlay.RoutePlayPixFallback', RoutePlayPixFallback);

 })(window);