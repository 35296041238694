(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play/assets/javascripts/client-error-report.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play/assets/javascripts/client-error-report.js');
"use strict";

const ClientErrorReport = _ref => {
  let {
    clientErrors = []
  } = _ref;
  if (!Array.isArray(clientErrors) || clientErrors.length === 0) {
    return null;
  }
  return React.createElement("div", {
    className: "pg_tipsen__client_error_report f-center fc-red"
  }, React.createElement("ul", null, clientErrors.map(error => React.createElement("li", {
    key: error.code
  }, error.message))));
};
setGlobal('svs.components.tipsen.routePlay.ClientErrorReport', ClientErrorReport);

 })(window);