(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play/assets/javascripts/skeleton/route-play-spela-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play/assets/javascripts/skeleton/route-play-spela-fallback.js');
"use strict";

const {
  CenterToScreen
} = svs.components.tipsen.centerToScreen;
const {
  SkeletonLine,
  SkeletonCircle
} = svs.components.lbUi;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  ReactPaper
} = svs.ui;
const RoutePlaySpelaRowFallback = _ref => {
  let {
    isWideScreen
  } = _ref;
  return React.createElement("div", {
    className: "coupon-match-row-skeleton"
  }, isWideScreen && React.createElement("div", {
    className: "coupon-match-skeleton"
  }, React.createElement(SkeletonCircle, {
    className: "coupon-match-number-skeleton",
    size: 36
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-team-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-team-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-sign-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-sign-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-sign-skeleton"
  })), !isWideScreen && React.createElement("div", {
    className: "coupon-match-mobile-skeleton"
  }, React.createElement(SkeletonCircle, {
    className: "coupon-match-mobile-number-skeleton",
    size: 30
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-mobile-sign-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-mobile-sign-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-mobile-sign-skeleton"
  }), React.createElement("div", null, React.createElement(SkeletonLine, {
    className: "coupon-match-mobile-team-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-mobile-team-skeleton"
  }))), React.createElement("div", {
    className: "coupon-match-info-skeleton"
  }, React.createElement(SkeletonLine, {
    className: "coupon-match-info-title-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  })), React.createElement("div", {
    className: "coupon-match-info-skeleton"
  }, React.createElement(SkeletonLine, {
    className: "coupon-match-info-title-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-match-info-data-skeleton"
  })));
};
const RoutePlaySpelaFallback = () => {
  const isWideScreen = useMediaQuery(breakpoints.up(BREAKPOINT_SM));
  const classNames = [];
  if (isWideScreen) {
    classNames.push('col-md-7');
  }
  return React.createElement(CenterToScreen, {
    className: "grid-row"
  }, React.createElement("div", {
    className: classNames.join(' ')
  }, isWideScreen && React.createElement("div", {
    className: "coupon-options-skeleton"
  }, React.createElement(ReactPaper, {
    branding: "sport",
    className: "coupon-options-item-skeleton coupon-options-item-skeleton-left"
  }, React.createElement(SkeletonLine, {
    className: "coupon-option-skeleton-button"
  })), React.createElement(ReactPaper, {
    branding: "sport",
    className: "coupon-options-item-skeleton coupon-options-item-skeleton-left"
  }, React.createElement(SkeletonLine, {
    className: "coupon-option-skeleton-button"
  })), React.createElement(ReactPaper, {
    branding: "sport",
    className: "coupon-options-item-skeleton coupon-options-item-skeleton-right"
  }, React.createElement(SkeletonLine, {
    className: "coupon-option-skeleton-button"
  }))), !isWideScreen && React.createElement("div", {
    className: "coupon-options-mobile-skeleton"
  }, React.createElement(SkeletonCircle, {
    className: "coupon-options-mobile-skeleton-circle",
    size: 24
  }), React.createElement(SkeletonLine, {
    className: "coupon-options-mobile-skeleton-line"
  })), React.createElement(ReactPaper, {
    branding: "sport"
  }, React.createElement(RoutePlaySpelaRowFallback, {
    isWideScreen: isWideScreen
  }), React.createElement(RoutePlaySpelaRowFallback, {
    isWideScreen: isWideScreen
  }), React.createElement(RoutePlaySpelaRowFallback, {
    isWideScreen: isWideScreen
  }), React.createElement(RoutePlaySpelaRowFallback, {
    isWideScreen: isWideScreen
  }), React.createElement(RoutePlaySpelaRowFallback, {
    isWideScreen: isWideScreen
  }), React.createElement(RoutePlaySpelaRowFallback, {
    isWideScreen: isWideScreen
  }), React.createElement(RoutePlaySpelaRowFallback, {
    isWideScreen: isWideScreen
  }), React.createElement(RoutePlaySpelaRowFallback, {
    isWideScreen: isWideScreen
  }))), isWideScreen && React.createElement("div", {
    className: "col-md-5"
  }, React.createElement(ReactPaper, {
    branding: "sport",
    className: "coupon-side-skeleton"
  }, React.createElement("div", {
    className: "coupon-side-menu-skeleton"
  }, React.createElement(SkeletonLine, {
    className: "coupon-side-menu-item-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-side-menu-item-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-side-menu-item-skeleton"
  }), React.createElement(SkeletonLine, {
    className: "coupon-side-menu-item-skeleton"
  })))));
};
setGlobal('svs.components.tipsen.routePlay.RoutePlaySpelaFallback', RoutePlaySpelaFallback);

 })(window);