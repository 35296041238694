(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play/assets/javascripts/skeleton/route-play-singles-fallback.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play/assets/javascripts/skeleton/route-play-singles-fallback.js');
"use strict";

const {
  CenterToScreen
} = svs.components.tipsen.centerToScreen;
const {
  SkeletonLine,
  SkeletonCircle
} = svs.components.lbUi;
const {
  ReactPaper
} = svs.ui;
const RoutePlaySingleRowFallback = () => React.createElement("div", {
  className: "coupon-single-match-skeleton"
}, React.createElement(SkeletonCircle, {
  className: "coupon-single-match-number-skeleton",
  size: 30
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-team-skeleton coupon-single-match-teamone-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-team-skeleton coupon-single-match-teamtwo-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signone-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signtwo-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signthree-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signone-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signtwo-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signthree-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signone-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signtwo-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signthree-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signone-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signtwo-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signthree-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signone-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signtwo-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signthree-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signone-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signtwo-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signthree-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signone-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signtwo-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-sign-skeleton coupon-single-match-signthree-skeleton"
}));
const RoutePlaySinglesFallback = () => React.createElement(CenterToScreen, {
  className: "grid-row"
}, React.createElement("div", {
  className: "route-singles-buttons-row-skeleton"
}, React.createElement(ReactPaper, {
  branding: "sport",
  className: "route-singles-buttons-skeleton route-singles-button-start-skeleton"
}, React.createElement(SkeletonLine, {
  className: "route-singles-buttons-skeleton"
})), React.createElement(ReactPaper, {
  branding: "sport",
  className: "route-singles-buttons-skeleton  route-singles-button-end-skeleton"
}, React.createElement(SkeletonLine, {
  className: "route-singles-buttons-skeleton"
}))), React.createElement(ReactPaper, {
  branding: "sport",
  className: "route-singles-skeleton"
}, React.createElement("div", {
  className: "route-singles-numbers-row-skeleton"
}, React.createElement("span", null), React.createElement(SkeletonLine, {
  className: "route-singles-number-skeleton"
}), React.createElement(SkeletonLine, {
  className: "route-singles-number-skeleton"
}), React.createElement(SkeletonLine, {
  className: "route-singles-number-skeleton"
}), React.createElement(SkeletonLine, {
  className: "route-singles-number-skeleton"
}), React.createElement(SkeletonLine, {
  className: "route-singles-number-skeleton"
}), React.createElement(SkeletonLine, {
  className: "route-singles-number-skeleton"
}), React.createElement(SkeletonLine, {
  className: "route-singles-number-skeleton"
})), React.createElement(RoutePlaySingleRowFallback, null), React.createElement(RoutePlaySingleRowFallback, null), React.createElement(RoutePlaySingleRowFallback, null), React.createElement(RoutePlaySingleRowFallback, null), React.createElement(RoutePlaySingleRowFallback, null), React.createElement(RoutePlaySingleRowFallback, null), React.createElement(RoutePlaySingleRowFallback, null), React.createElement(RoutePlaySingleRowFallback, null), React.createElement("div", {
  className: "coupon-single-match-row-buttons-skeleton"
}, React.createElement("span", null), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}), React.createElement(SkeletonLine, {
  className: "coupon-single-match-row-button-skeleton"
}))));
setGlobal('svs.components.tipsen.routePlay.RoutePlaySinglesFallback', RoutePlaySinglesFallback);

 })(window);