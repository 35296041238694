(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/route-play/assets/javascripts/no-draws.js') >= 0) return;  svs.modules.push('/components/tipsen/route-play/assets/javascripts/no-draws.js');
"use strict";

const {
  ReactPaper,
  ReactIcon
} = svs.ui;
const {
  WidgetArea
} = svs.components.lbUtils.widgetArea;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  Article
} = svs.components.tipsen.articleComponents;
const {
  noDrawsConfig
} = svs.tipsen.data;
const NoDraws = () => {
  var _noDrawsConfig$headin, _noDrawsConfig$icon;
  const wideScreen = useMediaQuery(breakpoints.up(BREAKPOINT_SM));
  const [productBranding] = useBranding();
  const noDrawsArticleId = noDrawsConfig === null || noDrawsConfig === void 0 ? void 0 : noDrawsConfig.article;
  const heading = (_noDrawsConfig$headin = noDrawsConfig === null || noDrawsConfig === void 0 ? void 0 : noDrawsConfig.heading) !== null && _noDrawsConfig$headin !== void 0 ? _noDrawsConfig$headin : 'Ingen öppen omgång finns';
  const icon = (_noDrawsConfig$icon = noDrawsConfig === null || noDrawsConfig === void 0 ? void 0 : noDrawsConfig.icon) !== null && _noDrawsConfig$icon !== void 0 ? _noDrawsConfig$icon : 'game-soccer';
  return React.createElement("div", {
    className: "tipsen-round-not-defined tipsen-center-to-screen"
  }, React.createElement(ReactPaper, {
    branding: "sport",
    className: "tipsen-round-not-defined-paper"
  }, React.createElement("div", {
    className: "tipsen-round-not-defined-div f-center"
  }, React.createElement(ReactIcon, {
    className: "round-not-defined-icon",
    color: "fc-".concat(productBranding),
    icon: icon,
    size: wideScreen ? '1000' : '900'
  }), React.createElement("div", {
    className: "f-800 round-not-defined-text f-center"
  }, heading), noDrawsArticleId && React.createElement(Article, {
    articleId: noDrawsArticleId,
    className: "tipsen-no-draws-article f-center",
    noArticleMessage: "F\xF6r tillf\xE4llet har vi ingen omg\xE5ng"
  }))), React.createElement(WidgetArea, {
    widgetArea: "noDrawsWidgetArea"
  }));
};
setGlobal('svs.components.tipsen.routePlay.NoDraws', NoDraws);

 })(window);